
.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; 
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pie-chart {
  max-width: 100%;
  height: auto;
}
