.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}


.css-1p9iw00-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
    box-sizing: border-box;
    width: 265px;
    margin-top: 60px;
}

.titlebold {
    font-weight: bold;
}

.hideshowlbl {
    text-transform: uppercase;
    font-weight: bold;
}

.smallfont {
    font-size: 13px;
}

.liftup {
    position: relative;
    top: -8px;
    z-index: 101;
}



.bgblue {
    background-color: #3d77dc;
    height: 55vh;
}



.innertext {
    padding-left: 10px;
    color: #9ea0a9;
    font-weight: 600;

}

.searchtext {
    background-color: #fff;
    font-weight: bold;
}

.footer {
    background-color: #7e779b;
}

/* body {
    letter-spacing: 1px;
} */


.searchpanel {

    z-index: 999 !important;
    background-color: #ffffff;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 30px;

}

.borderA {
    background-color: #d5e5fd;
    height: 20px;
}


.headerp {
    font-family: Quicksand, Avenir, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: bold;
    color: white;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 2px;
    padding: 5px;
    position: relative;
    top: -8px;
    ;
}

.hoverstyle {
    border-radius: 20px;
    background-color: #b9d9f8;
    padding: 7px;
    color: #3d77dc;
}

.hoverstyle .innertext {
    color: #3d77dc;
}



.status {
    color: rgb(255, 255, 255);
    font-weight: 600;
    background-color: orangered;
    padding: 7px;
    border-radius: 10px;
    max-width: 100px !important;
    font-size: medium;

}

a:-webkit-any-link {
    color: #3d77dc;
}

.radiopanel {
    padding: 7px;
}

.liftup1 {
    z-index: 100;
}

.purplebg {
    text-align: "left";
    font-weight: "bold";
    color: "white";
    background-color: "#2f2fa2";
    padding: "20px";
    text-align: "left";
}

.liftup2 {
    z-index: 200;
}

a:link {
    color: black;
}

.signheader {
    background-color: #2f2fa2;
    padding: 20px;
    /* border-radius: 7px; */
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

}


.personicon {
    font-size: "7px";
    position: relative;
    top: 5px;
    left: -5px;

}

.phoneicon {
    font-size: "7px";
    position: relative;
    top: 5px;
    left: -5px;

}

.locationicon {
    font-size: "7px";
    position: relative;
    top: 5px;
    left: -5px;

}

.emailicon {
    font-size: "7px";
    position: relative;
    top: 5px;
    left: -5px;
}



.search {
    margin-top: 18px !important;
}

/* .btnsign {
    width: 263px !important;
} */

.sign {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* border: 2px solid #3c76d2; */
    /* padding-bottom: 10px; */
}

.addmargin10 {
    margin: 10px !important;
}

.addmargin20 {
    margin: 20px !important;
}

.toppadding10 {
    padding-top: 20px;
}

.addpadding10 {
    padding-top: 20px;
}

.bold {
    font-weight: bold;
}

.addpanel {
    border: 2px solid grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.textrow {
    padding: 20px;
    text-align: left;
    margin: 10px;

}

.gridrow {
    padding: 20px;
    border-bottom: 2px solid grey;
    text-align: left;
    width: 80%;
}

.userstatus {
    position: relative;
    top: -15px;
    /* left: 70px; */
}


.accdetails {
    position: relative;
    top: -5px;
}

/* .gridrow::after {
    content: </hr>;
} */
.accsummary {
    background-color: #2f2fa2;
    margin-top: 13px;
    padding: 10px;
    margin: 10px;
    color: white;
    position: relative;
    top: 16.5px;
    width: 103.5% !important;
}

.addmargin10 {
    margin: 10px;
}

.error {
    color: #d32f2f;
    font-size: 12px;
    margin-bottom: -15px !important;
}

.container {
    position: relative;
    /* overflow: hidden; */
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.btndark {
    background-color: #2f2fa2 !important;
}

.btndark:hover {
    background-color: #2f2fa2 !important;
}

.btn-light {
    background-color: #f0f8ff !important;
    color: black !important;
}

.btn-light:hover {
    background-color: #f0f8ff !important;

}

.btn-white {
    background-color: #ffffff !important;
    color: #2f2fa2 !important;
}

.btn-white:hover {
    background-color: #ffffff !important;

}

.shadows {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.222) !important;
}

.text-field-with-error {
    border: 1px solid;
    border-color: #c4c4c4;
    outline: 1px solid red;
    outline-offset: 1px;
}

.amt {
    background-color: #eff7fe;
    color: white;
}

.chkbox {
    width: 15px;
    height: 15px;
}

.download-name {
    color: #2f2fa2 !important;
    /* background-color: red; */
    /* font-weight: 700; */
    font-size: 18px;
    text-decoration: none !important;
}

@media screen and (max-width: 550px) {

    .subsdetails {
        margin-left: 10px;
    }

    .bizdetails {
        font-size: 40px;
    }
}

@media screen and (min-width: 550px) {

    .subsdetails {
        margin-left: 10px;
    }

    .userlabel {
        display: block;
    }

    .hideshowlbl {
        display: none;
        /* text-align: center; */
    }

    .hideshowheader {
        display: block;
        /* text-align: center; */
    }
}


@media screen and (max-width: 900px) {
    .hidegrid {
        display: none;
    }

    .userlabel {
        display: none;
    }

    .hideshowheader {
        display: none !important;
        text-align: left;

    }

    .hideshowlbl {
        display: block;
        text-align: left;

    }
}

@media screen and (max-width: 1200px) {

    .userlabel {
        display: none !important;
        color: red;
    }
}




.button-clr-width{
    border-radius: 20px !important;
    width: 200px !important;
}
.button-all-clr{
    background-color: #7F63F4 !important;
    border-radius: 0px !important;
    color: white !important;
}
.button-all-clr-outlined{
    border-radius: 0px !important;
    border-color: #7F63F4 !important;
  color: #7F63F4 !important;
}
/* Table CSS*/

.content-tabel-head{
    font-size: 13px !important;
    font-weight: bold !important;
    color: #6A707E !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 0.9rem !important;
}
.css-1sjyd0y{
    background-color: #F3F6F9 !important;
}

