/* ReportGraph.css */
.report-card {
    margin: 10px;
  }
  
  .chart-container {
    width: 100%;
    height: 400px; 
    max-width: 600px; /* Set a maximum width for the chart */
  }
  
  @media (max-width: 600px) {
    .chart-container {
      height: 300px; 
    }
  }
  .toggle-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000; /* Light mode text color */
  }
  
  .dark-mode .toggle-container {
    color: #fff; /* Dark mode text color */
  }
  