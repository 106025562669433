.content_Price {
  color: #fff;
  font-size: 32px;
  opacity: 1;
}

/* .content_cardDesin {
  width: 295px !important;
  height: 100px !important;
} */

.title {
  font-size: 15px;
}



/* .css-1p9iw00-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  box-sizing: border-box;
  width: 265px;
  margin-top: 58px;
} */

.icon_desin {
  opacity: 1;
  color: rgba(255, 255, 255, 0.868);
  font-size: 65px !important;
  margin-left: 25px;
  margin-top: 10px;
}

/* .content_emptyspace {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.15;
  width: 40px;
  height: 40px;
  border-radius: 50%; 
  position: relative;
  top: -60px;
  left: 15px;
} */

.icon_desin {
  opacity: 1;
  color: rgba(255, 255, 255, 0.868);
  position: relative;
  top: -10px;
}

/* side bar buttons css start */
/* .side-bar-btn {
  
  background-color: #f0f8ff !important;
  width: 317px !important;
  height: 56px;
  border-radius: 0px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #6a707e !important;
  box-shadow: 0.47px 3px 10px #7777771a;
  position: relative;
  bottom: 100px;
} */

/* .side-bar-btn:hover {
  background-color: #0000001a !important;
  color: #506df9 !important;
} */

.sidebar-btn-container {
  background-color: #506df9;
  position: relative;
  top: 103px;
}

.sidebar_main_container {
  width: 320px;
  /* height: 96vh; */
  background-color: #f0f8ff;
}

.parenttitle {
  margin-bottom: 5px !important;
  position: relative;
  top: -12px
}

.css-1wuhnqw {
  position: absolute;
  right: 24px;
  top: 7px;
}

/* .css-1mw7z69-MuiPaper-root-MuiAppBar-root {
  background-color: #2f2fa2 !important;
  padding: 20px !important;
} */
/* side bar buttons css end */

.signin {
  color: #6a707e !important;
}

/* @media screen and (max-width: 756px) {

  .toptitle {
    font-size: 17.3px !important;
  }
}

@media screen and (max-width: 349px) {

  .toptitle {
    font-size: 14.4px !important;
  }
}

@media screen and (max-width: 900px) {
  .toptitle {
    font-size: smaller !important;
  }
}


@media screen and (max-width: 900px) {
  .toptitle {
    font-size: smaller !important;
  }
} */