/* dashboard icon style start*/
.style-icon {
  color: #aaaaaa;
  font-size: 16px;
}

/* dashboard icon style End*/

/* logo icon style start*/
.logo-container {
  width: 320px !important;
  height: 98px !important;
  /* background-color: #506df9; */
  background-color: #2f2fa2;
  letter-spacing: 0px;
  border-radius: none !important;
}

.logo-text {
  text-align: left !important;
  color: #ffffff !important;
  opacity: 1;
  position: relative;
  top: 30px;
  left: 58px;
  width: 171px;
  height: 38px;
  font: normal normal normal 29px/35px Circular Std Medium, Regular !important;
}

/* logo icon style End*/

/* side bar txt css start */
.nav_text {
  color: #abafb3;
  /* background-color: #506df9; */
  width: 78px;
  text-align: left;
  font: normal normal normal 12px/50px Circular Std Bold !important;
  position: absolute;
  left: 50px;
  top: 115px;
}

/* side bar txt css end */
/* avatar bar txt css start */
.avatar {
  background-color: #506df9 !important;
}

/* avatar bar txt css end */

/* avatar bar txt css start */
.txtFild {
  color: #f3f3f3 !important;
  /* background-color: #f3f3f3 !important; */
  width: 351px;
  height: 50px;
  /* border: solid 1px #f3f3f3 !important; */
  border-radius: 3px;
}

/* avatar bar txt css end */